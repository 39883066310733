/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/components/custom.css'
import './src/components/tailwind.css'

import React from 'react'
import { setConfiguration } from 'react-grid-system'

import WrapWithProvider from './src/helpers/wrap-with-provider'

// import './src/components/global.scss'
setConfiguration({ maxScreenClass: 'xl' })

export const shouldUpdateScroll = ({ routerProps }) => {
  const disableScrollUpdate = routerProps?.location?.state?.disableScrollUpdate
  return !disableScrollUpdate
}

export const wrapRootElement = WrapWithProvider
