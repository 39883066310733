import { LocationProvider } from '@reach/router'
import { IntlProvider } from 'gatsby-plugin-react-intl'
import React from 'react'

export const WrapWithProvider = ({ element, props }: any) => {
  const locale = element?.props?.pageContext?.language ?? 'en'
  return (
    <IntlProvider
      {...element?.props?.pageContext?.intl}
      locale={locale}
    >
      <LocationProvider {...props}>{element}</LocationProvider>
    </IntlProvider>
  )
}

export default WrapWithProvider
